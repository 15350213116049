<template>

    <div>
       <iframe allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
               style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;
               top:0px;left:0px;right:0px;bottom:0px" height="100%" width="100%"
               :src="url"></iframe>
    </div>

</template>

<script>

export default {
      name: "Community2",
  data(){
        return{
          url:"/community"
        }
  },

// https://www.mybeedroid.eu/community/4d25ec34655d5a172e1fcea3eabd9dc1188763d380f077ea8a01132ab2ce84c8c88a63
  mounted() {

        if (this.$store.getters.me.id>0 && this.$store.getters.me.company_accepted_at==null){
          this.$router.push('/main/non-confirmed')
          return
        }

        console.log(' path ',this.$route.params.logintoken)
        if (this.$route.params.logintoken) {
          localStorage.setItem('user-token', this.$route.params.logintoken)
          this.url = "/community/"+this.$route.params.logintoken
        }

        //console.log("mounted com")
  }
}
</script>

<style scoped lang="scss">

.crm-nahledy{
  display: block;
  margin-right: calc(100vw - 860px);
  @media (min-width: 1150px){
    margin-right:300px;
  }

  padding:0 ;
}


</style>
